import React from 'react';
import Button from "../components/Button";
import { Link } from "./../util/router";

function Checkout() {
  return (
    <div
      className="h-screen flex flex-col items-center justify-start py-10"
      style={{ backgroundColor: '#DDDDDD' }} // Explicitly define the background color
    >
      {/* Content Wrapper */}
      <div className="w-[400px]">
        {/* Top Section */}
        <div className="top-section flex items-center mb-12">
          {/* Left: Image */}
          <div className="flex-shrink-0">
            <img
              src="/coversmall.jpg" // Hosted image path
              alt="Cover Image"
              className="responsive-img"
              width="192px"
              height="300px"
              style={{ height: '125px', width: 'auto' }}
            />
          </div>
          {/* Right: Text */}
          <div className="ml-4">
            <p className="text-lg font-bold">Naturally Lean Blueprint</p>
            <p className="text-gray-600 text-sm">Step by Step to a Lean, Strong, Sculpted Body</p>
            <p className="text-xl font-semibold">$39</p>
          </div>
        </div>
        <div className="text-center">
            <Button

                href="https://payhip.com/buy?link=YEymb"
                size="xl"
                className="w-[85%] text-center mx-auto"
                >
                Checkout
            </Button>
        </div>

        <p className="text-gray-600 text-sm text-center">90-day Total Satisfaction Guarantee</p>
      </div>
    </div>
  );
}

export default Checkout;
