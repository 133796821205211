import React from "react";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Button from "./Button";

function FaqSection(props) {
  const faqItems = [
    {
      question: "Who is this program designed for?",
      answer:
        "This program is designed for men and women all ages. No matter your gender or age, the fundamentals are the same. However, our fitness program has options based on your mobility level and also your physique goals.",
    },
    {
      question: "Do you offer a guarantee?",
      answer:
        "Yes, we have a 90-day 100% satisfaction guarantee. If you're not totally satisfied, contact our support for a refund.",
    },
    {
      question: "What is Naturally Lean Blueprint?",
      answer:
        "Naturally Lean Blueprint is a PDF ebook with a program and workbooks designed to transform your habits and your diet in a sustainable way. The exercise component is a progressive resistance training plan designed to aid fat loss and build lean muscle.",
    },
    {
      question: "How quickly can I expect to see results?",
      answer:
        "With Naturally Lean Blueprint, the focus is on making a sustainable diet & exercise plan that works with your life and becomes second nature. Expect to start gradually transforming your habits from the first day. While everyone has different timelines, you can usually achieve 2-6 lbs. of healthy, sustainable weight loss per month, or even more, by following the program consistently. You'll lose fat while maintaining (or even gaining) lean muscle, keeping and building your fitness. You'll have the tools to lose weight at exactly the rate you want.",
    },
  ];

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="space-y-12 container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          strapline={props.strapline}
          className="text-center"
        />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12">
          {faqItems.map((item, index) => (
            <div className="prose prose-indigo" key={index}>
              <h4>{item.question}</h4>
              <p>{item.answer}</p>
            </div>
          ))}
        </div>

        {props.showSupportButton && (
          <div className="text-center">
            <Button
              href={props.supportUrl}
              target="_blank"
              size="lg"
              variant="simple"
              startIcon={
                <ArrowTopRightOnSquareIcon className="opacity-50 inline-block w-5 h-5" />
              }
            >
              Go to support center
            </Button>
          </div>
        )}
      </div>
    </Section>
  );
}

export default FaqSection;
