import React from "react";
import "./../styles/global.css";
import IndexPage from "./index";
import Checkout from "./checkout";
import { Switch, Route, Router } from "./../util/router";
import NotFoundPage from "./404";
import "./../util/analytics";
import Chat from "./../components/Chat";

function App(props) {
  return (
    <>
      <Chat />
      <Router>
        <Switch>
          <Route exact path="/" component={IndexPage} />
          <Route exact path="/checkout" component={Checkout} />
          <Route component={NotFoundPage} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
