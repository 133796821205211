import React from "react";
import Meta from "./../components/Meta";
import HeroSection from "./../components/HeroSection";
import CtaSection from "./../components/CtaSection";
import CtaSection2 from "./../components/CtaSection2";
import FaqSection from "./../components/FaqSection";
import Footer from "./../components/Footer";
import Section from "../components/Section";
import SectionHeader from "../components/SectionHeader";
import Button from "../components/Button";
import { Link } from "./../util/router";
import { ArrowRightIcon } from "@heroicons/react/24/solid";

function IndexPage(props) {
  return (
    <>
      <Meta />
      <HeroSection
        title="Strong, Lean, and Sculpted Body"
        subtitle="Step-by-Step To Master the Habits for a"
        strapline=""
        size="lg"
        bgColor="bg-blue-900"
        bgImage=""
        bgImageOpacity={1}
        textColor="text-white"
      />
      <Section
        size={props.size}
        bgColor={props.bgColor}
        bgImage={props.bgImage}
        bgImageOpacity={props.bgImageOpacity}
        textColor={props.textColor}
        title="Lose fat, get lean & sculpt your body the healthy way"
        subtitle="Step-by-step plan to master the diet and exercise habits that burn fat effectively."
        strapline=""
      >
        <div className="space-y-10 container">
          <div className="text-left leading-loose">
              <header
                className={
                  "mb-8 last:mb-0" + (props.className ? ` ${props.className}` : "")
                }
              >
                {props.strapline && (
                  <div className="text-sm uppercase font-bold tracking-wider mb-1 text-blue-700">
                    {props.strapline}
                  </div>
                )}
                <h1 className="text-3xl md:text-4xl font-extrabold"></h1>
              

{
//   <h1 className="text-3xl md:text-4xl font-extrabold">Does this sound familiar?</h1>
// <p className="mt-4 text-lg md:text-xl text-left">
//     Weight that won’t budge, no matter what you try. <br /><br />
//     Confusing, conflicting advice from all directions. <br /><br />
//     Struggling to stay consistent and see results. <br /><br />
//     Frustration when your efforts don’t pay off.<br /><br />
// </p>
}


<CtaSection
        title="Lose fat, get lean & sculpt your body the healthy way"
        subtitle="Get started with a step-by-step plan to master the diet and exercise habits that burn fat effectively."
        strapline=""
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />

<h2 className="text-3xl md:text-4xl font-extrabold">What is Naturally Lean Blueprint?</h2>
<p className="mt-4 text-lg md:text-xl text-left">
    We wanted a program that would help people get lean and fit, no matter where they're starting from. We wanted something that fits into your daily life, embraces the joy of food, and breaks free from an all-or-nothing mindset. <br /><br />
    We couldn't find what we wanted out there, so we created Naturally Lean Blueprint, your step-by-step guide to transforming your body and health and achieving a strong, lean, and sculpted body. <br /><br />

    The focus is on health and fitness above all else. But that's also the path to achieving a lean, sculpted physique. <br /><br />
</p>


<h2 className="text-3xl md:text-4xl font-extrabold">Our Approach</h2>
<p className="mt-4 text-lg md:text-xl text-left">
    We can build a diet that helps us feel satisfied, not one that relies entirely on willpower.<br /><br />
    We can learn to trust the instincts we already have and reprogram ourselves to make the right choices in our diet. <br /><br />
    We can eat a diet that works for us, not one that forces unrealistic restrictions. <br /><br />
    We can focus on exercises that have the most impact for our time, and ensure we lose fat, not muscle. <br /><br />
</p>
{/* <CtaSection
        title="Lose Fat Sustainably with a Flexible, Satiating Diet"
        subtitle="Follow a high-protein, high-fiber diet that keeps you feeling satisfied while saving room for the foods you love."
        strapline=""
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      /> */}

<h3 className="mt-8 text-3xl md:text-4xl font-extrabold">The Problem with Other Programs:</h3>
<p className="mt-4 text-lg md:text-xl text-left">
    Restrictive and unsustainable diets. <br /><br />
    Focus on just getting smaller, rather than building fitness and lean muscle. <br /><br />
    Ignoring the critical importance of protein and resistance training. <br /><br />
    Offering calorie or protein targets without actionable strategies to meet them.<br /><br />
</p>

<h3 className="text-3xl md:text-4xl font-extrabold">What We Do Differently:</h3>
<p className="mt-4 text-lg md:text-xl text-left">
    At Naturally Lean Blueprint, we put a strong focus on body composition—helping you maintain lean muscle so you stay fit, strong, and toned. <br /><br />
    Our approach centers on satiating diets that keep you full and satisfied, so you’re not relying on sheer willpower. <br /><br />
    We don’t believe in strict, rigid diets. Instead, we focus on flexibility, encouraging you to eat right 80-90% of the time and enjoy balance in your life. <br /><br />
    We combine all of this with mental techniques to make building healthy habits easier and more natural, so you can stay on track and feel great doing it.
</p>

<p className="mt-4 text-lg md:text-xl text-left">
    Whether you’ve struggled with weight for life or can’t shake the pounds after gaining them, you’ve got this. <br /><br />
    With Naturally Lean Blueprint, we’ll help you succeed.
</p>

<h2 className="mt-4 text-3xl md:text-2xl font-extrabold">Lose Fat Sustainably with a Flexible, Satiating Diet</h2>
<p className="mt-4 text-lg md:text-xl text-left">
Follow a high-protein, high-fiber diet that keeps you feeling satisfied while saving room for the foods you love.
</p>


<h2 className="mt-4 text-3xl md:text-2xl font-extrabold">Exercises to Build a Strong, Sculpted Body and Accelerate Burning Fat</h2>
<p className="mt-4 text-lg md:text-xl text-left">
Time-efficient workout programs designed to improve your body composition, adaptable to your busy schedule.

</p>
<p className="mt-4 text-lg md:text-xl text-left">
If you’re new to fitness, start on a beginner plan and steadily build your strength.
If you’re already exercising, use our workout templates to concentrate your efforts on the most effective exercises for fat loss, maximizing your results without wasting time.
</p>



<h2 className="mt-4 text-3xl md:text-2xl font-extrabold">Mental Strength: Focus and Goal-Setting for Lasting Results</h2>
<p className="mt-4 text-lg md:text-xl text-left">
Consistency and patience are essential for successful fat loss. Weekly mindset exercises keep you focused on your goals and help maintain your motivation throughout the journey.
</p>

                
              </header>
            
          </div>
        </div>
        <br />
      </Section>

      <CtaSection
        title="Sustainable Fat Loss, Not Overly Restrictive Diets"
        subtitle="Build lasting habits to lose fat and keep it off for life, in a way that fits into your lifestyle. Lose fat the healthy way, without crash dieting."
        strapline=""
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
      {/* <CtaSection
        title="Weekly resistance training and exercise templates"
        subtitle="Time-efficient workout programs designed to improve your body composition, adaptable to your busy schedule."
        strapline=""
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />

      <CtaSection
        title="For Both New And Experienced Exercisers"
        subtitle="If you’re new to fitness, start on a beginner plan and steadily build your strength. If you’re already exercising, use our workout templates to concentrate your efforts on the most effective exercises for fat loss, maximizing your results without wasting time."
        strapline=""
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
      <CtaSection
        title="Mental Strength: Focus and Goal-Setting for Lasting Results"
        subtitle="Consistency and patience are essential for successful fat loss. Weekly mindset exercises keep you focused on your goals and help maintain your motivation throughout the journey."
        strapline=""
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
      <CtaSection2
        title="Maximize Your Chances of Success"
        subtitle="A step-by-step plan, weekly check-ins, and community support to keep you on track every week. We’re confident our program will help you achieve your fat loss goals, backed by a lifetime guarantee."
        strapline=""
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
      <CtaSection
        title="Maximize Your Chances of Success"
        subtitle="A step-by-step plan, weekly check-ins, and community support to keep you on track every week. We’re confident our program will help you achieve your fat loss goals, backed by a lifetime guarantee."
        strapline=""
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
    /> */}
      <FaqSection
        title="Frequently Asked Questions"
        subtitle=""
        strapline="We answer"
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
        showSupportButton={false}
        supportUrl="https://zendesk.com"
      />

<CtaSection
        title="Start Your Transformation - 100% Satisfaction Guarantee"
        subtitle="Avoid the weight-loss traps and build proven habits for fat loss and a healthy, lean physique. Try it risk-free, backed by a 90-day 100% money-back satisfaction guarantee. "
        strapline=""
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
      <Footer
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
        sticky={false}
      />
    </>
  );
}

export default IndexPage;
