import React from "react";
import { CubeTransparentIcon, ArrowRightIcon } from "@heroicons/react/24/solid";
import Section from "./Section";
import { Link } from "./../util/router";
import SectionHeader from "./SectionHeader";
import Button from "./Button";

function HeroSection(props) {
  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="absolute inset-x-0 bottom-0 text-blue-700 text-opacity-50">
        <svg fill="currentColor" viewBox="0 0 500 150">
          <path d="M-0.84,34.03 C187.63,148.52 327.03,-4.44 499.72,40.95 L500.00,150.00 L0.00,150.00 Z" />
        </svg>
      </div>
      <div className="relative container">
        
        <div className="text-center pb-16">
          <header
            className={
              "mb-8 last:mb-0" + (props.className ? ` ${props.className}` : "")
            }
          >
            {props.strapline && (
              <div className="text-sm uppercase font-bold tracking-wider mb-1 text-blue-700">
                {props.strapline}
              </div>
            )}

            {props.subtitle && (
              <h2 className="mt-4 inline-block text-lg md:text-xl md:leading-relaxed font-medium max-w-screen-md opacity-80">
                {props.subtitle}
              </h2>
            )}

            {props.title && (
              <h1 className="text-3xl md:text-4xl font-extrabold">{props.title}</h1>
            )}
          </header>
        </div>
        <div className="flex justify-center pb-0 md:pb-0">
        <div className="pb-0 md:pb-0 mx-5 max-w-xs">
          <div className="relative mx-5 lg:mx-5"  bgcolor="#FF0000">
            <div className="absolute inset-0 rounded-xl bg-blue-600 bg-opacity-20 -m-4 transform rotate-2" />
            <div className="absolute inset-0 rounded-xl bg-blue-600 bg-opacity-25 -m-4 transform -rotate-2" />
            <img
              className="relative rounded-lg mx-auto shadow-lg w-[250px]"
              src="cover.jpg"
              width="500px"
              height="781px"
              alt=""
            />
          </div>
        </div>
        </div>
        <div className="text-center pt-16 pb-4">
          <Button
              component={Link}
              to="/checkout"
              size="xl"
              variant="secondary"
              endIcon={
                <ArrowRightIcon className="opacity-70 inline-block w-5 h-5" />
              }
            >
            Start Your Transformation
          </Button>

          <p className="text-sm pt-4">$39 — backed by a satisfaction guarantee</p>
        </div>
      </div>
    </Section>
  );
}

export default HeroSection;
